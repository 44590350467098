































import { defineComponent, computed, ref, watch, PropType } from '@vue/composition-api'
import { LeagueVolunteerRole } from '@/GeneratedTypes/LeagueVolunteerRole'
import { RoleElementType } from '@/models/RoleElement/RoleElement'
import { cloneDeep, difference, intersection } from 'lodash'
import { TypeLeagueVolunteerRole } from '@/GeneratedTypes/TypeLeagueVolunteerRole'
import { roleById } from '@/lib/support/models/UpwardTypes/TypeLeagueVolunteerRole/data'
import RoleElement from '@/elements/css/RoleElement.vue'
import ModalMultiSelect from '@/components/ModalMultiSelect.vue'
import { getEmptyLeagueVolunteerRole, roleIDSeed } from '@/lib/support/models/LeagueVolunteerRole/data'
import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import RoleApproval from '@/views/Programs/Volunteers/vues/RoleApproval.vue'

export default defineComponent({
  name: 'VolunteerRoleManager',
  components: { RoleApproval, CheckboxInput, ConfirmDeleteModal, ModalMultiSelect, RoleElement },
  props: {
    value: { required: true, default: () => [], type: Array as PropType<LeagueVolunteerRole[]> },
    roleDescriptions: {
      required: true,
      default: () => [],
      type: Array as PropType<TypeLeagueVolunteerRole[]>,
    },
    suggestedRoles: { required: true, default: () => [], type: Array as PropType<number[]> },
  },
  setup(props, ctx) {
    const internalRoles = ref<LeagueVolunteerRole[]>([])
    const selectOpen = ref(false)
    const removeConfirmBoxOpen = ref(false)
    const rolesPendingDelete = ref<number[]>([]) // role to delete or none.
    const suggestedRolesOpen = ref(false)

    /**
     * Event on role remove click -- feeds into confirmedRemoveRole if user confirm.
     */
    function removeRole(role: number) {
      rolesPendingDelete.value = [role]
      removeConfirmBoxOpen.value = true
    }

    /**
     * event for confirm of role removal
     */
    function confirmedRemoveRole() {
      internalRoles.value = [
        ...internalRoles.value.filter((x) => rolesPendingDelete.value.indexOf(x.roleID) < 0),
      ]
      input() //tell parent
    }

    function add() {
      selectOpen.value = true
    }

    /***
     * Returns the list of roles the user is being offered depending on
     * whether they are seeing all roles or suggested roled
     */
    function displayingRoles() {
      return suggestedRolesOpen.value
        ? intersection(props.suggestedRoles.map(String), currentRoleIds.value)
        : currentRoleIds.value
    }

    /***
     * Save gets hit on multiselect, passes list of selected values as string[]
     */
    function save(roles: string[]) {
      const displaying = displayingRoles()
      const rolesToDelete: string[] = difference(displaying, roles)
      const rolesToAdd: string[] = difference(roles, displaying)

      internalRoles.value = [
        ...internalRoles.value,
        ...rolesToAdd.map((x) => getEmptyLeagueVolunteerRole(roleIDSeed(parseInt(x)))),
      ]

      if (rolesToDelete?.length) {
        rolesPendingDelete.value = [...rolesToDelete.map((x) => parseInt(x))]
        removeConfirmBoxOpen.value = true // do a confirm on removed roles.
      }
      // update parent with the adds, deletes will update after confirm.
      input()
    }

    function input() {
      ctx.emit('input', internalRoles.value)
    }

    function saveSuggested(saves: string[]) {
      save(saves)
    }

    watch(
      () => props.value,
      () => {
        internalRoles.value = cloneDeep(props.value)
      },
      { immediate: true }
    )

    const suggestedRoleList = computed(() =>
      props.suggestedRoles.map((x) => ({
        id: x,
        description: roleById(x, props.roleDescriptions)?.description ?? 'n/a',
      }))
    )

    /***
     * Suggested Roles gives a chance to prompt the user for what should be
     * the role they want the user to have.
     */
    watch(
      () => props.suggestedRoles,
      () => {
        const numIntersectionOfSuggested = intersection(
          internalRoles.value.map((x) => x.roleID),
          props.suggestedRoles
        ).length
        if (numIntersectionOfSuggested == 0 && props.suggestedRoles.length == 1) {
          // we can add one role without asking the user.
          internalRoles.value.push(getEmptyLeagueVolunteerRole(roleIDSeed(props.suggestedRoles[0])))
          input() //trigger change in parent
          return
        }
        if (!numIntersectionOfSuggested) {
          //force the dialog.
          suggestedRolesOpen.value = true
        }
      }
    )

    const currentRoleIds = computed((): string[] => internalRoles.value.map((x) => x.roleID.toString()))

    /**
     * Fixes role List to include string id.
     */
    const roleValues = computed(() => {
      return cloneDeep(props.roleDescriptions)
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map((x) => ({
          id: x.id.toString(),
          description: x.description,
        }))
    })

    /**
     * Role list for role elements.
     */
    const roleList = computed((): RoleElementType[] => {
      return internalRoles.value.map((x) => ({
        id: x.roleID,
        description: roleById(x.roleID, props.roleDescriptions)?.description ?? 'n/a',
      }))
    })

    return {
      add,
      removeRole,
      roleList,
      selectOpen,
      roleValues,
      currentRoleIds,
      save,
      removeConfirmBoxOpen,
      confirmedRemoveRole,
      suggestedRolesOpen,
      suggestedRoleList,
      saveSuggested,
    }
  },
})
