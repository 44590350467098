

































import { defineComponent, ref, watch, computed, PropType } from '@vue/composition-api'
import SearchModal from '@/elements/SearchModal.vue'
import { LeagueVolunteerInfo } from '@/GeneratedTypes/ListInfo/LeagueVolunteerInfo'
import { CoachProgramInfo } from '@/GeneratedTypes/ListInfo/CoachProgramInfo'
import {
  coachLinkSearchHeaders,
  CoachLinkSelectionGridInfo,
  empty as emptyCoachLinkSelectionGridInfo,
} from '@/lib/components/CoachSelectionModal/CoachLinkSelectionGridColumns'
import store from '@/store'

export default defineComponent({
  components: {
    SearchModal,
  },
  data() {
    return { coachLinkSearchHeaders }
  },
  props: {
    open: { type: Boolean, required: true, default: false },
    typeProgramID: { type: String, required: true, default: '' },
    leagueID: { type: String, required: true, default: '' },
    searchInput: { type: String, required: false, default: '' },
    loading: { type: Boolean, required: false, default: false },
    coaches: {
      type: Array as PropType<LeagueVolunteerInfo[]>,
      required: true,
      default: [] as LeagueVolunteerInfo[],
    },
  },
  setup(props, { emit }) {
    const internalLoading = ref(props.loading)
    const internalSearchInput = ref(props.searchInput)
    const internalCoaches = ref(props.coaches as LeagueVolunteerInfo[])
    const selectedItem = ref(emptyCoachLinkSelectionGridInfo() as CoachLinkSelectionGridInfo)
    const selectedRow = ref(emptyCoachLinkSelectionGridInfo() as CoachLinkSelectionGridInfo)
    const showModal = ref(props.open)
    const showWarning = ref(false)
    const currentVolunteer = computed(() => store.getters.volunteers.volunteerUnderEdit)
    const selected = (s: CoachLinkSelectionGridInfo) => {
      selectedItem.value = s
    }
    const searchFilter = (c: LeagueVolunteerInfo) => {
      const name = c.formattedNameFirstLast ?? ''
      const term = internalSearchInput.value?.trim() ?? ''
      return !term || name.toUpperCase().includes(term.toUpperCase())
    }
    const programFilter = (c: LeagueVolunteerInfo) => {
      const programs = c.coachPreferences?.programs ?? ([] as CoachProgramInfo[])
      return programs.filter((p) => p.typeProgramID == props.typeProgramID).length > 0
    }
    const searchData = computed(() => {
      return internalCoaches.value
        .filter((v) => programFilter(v) && searchFilter(v))
        .map(
          (c): CoachLinkSelectionGridInfo => {
            const coachProgram = (c.coachPreferences?.programs ?? ([] as CoachProgramInfo[])).filter(
              (p) => p.typeProgramID == props.typeProgramID
            )[0]
            return {
              individualID: c.individualID,
              firstName: c.firstName!,
              lastName: c.lastName!,
              name: c.formattedNameFirstLast!,
              gender: c.gender!,
              selected: selectedItem.value.individualID == c.individualID,
              connectedCoachLinkFirstName: coachProgram.coachLinkFirstName!,
              connectedCoachLinkLastName: coachProgram.coachLinkLastName!,
              connectedCoachLinkID: coachProgram.coachLinkID,
              prettyConnectedTo: `${coachProgram.coachLinkFirstName} ${coachProgram.coachLinkLastName}`,
            }
          }
        )
    })
    const updateSearch = (t: string) => {
      internalSearchInput.value = t
    }
    const warningCheck = (a: CoachLinkSelectionGridInfo) => {
      selectedRow.value = a
      const hasConnecion = `${a?.connectedCoachLinkFirstName}${a?.connectedCoachLinkLastName}`.length > 0
      showWarning.value = hasConnecion && !a?.selected
    }
    watch(
      () => props.loading,
      () => {
        internalLoading.value = props.loading
      },
      { immediate: true }
    )
    watch(
      () => props.searchInput,
      () => {
        internalSearchInput.value = props.searchInput
      },
      { immediate: true }
    )
    watch(
      () => selectedItem.value,
      () => {
        emit('selected', selectedItem.value)
      }
    )
    watch(
      () => props.open,
      () => {
        showModal.value = props.open
      }
    )
    watch(
      () => showModal.value,
      () => {
        emit('show', showModal.value)
      }
    )
    watch(
      () => props.coaches,
      () => {
        internalCoaches.value = props.coaches
      },
      { immediate: true, deep: true }
    )
    return {
      showModal,
      showWarning,
      searchData,
      internalSearchInput,
      internalLoading,
      selectedItem,
      currentVolunteer,
      selectedRow,
      selected,
      updateSearch,
      warningCheck,
    }
  },
})
