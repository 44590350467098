
























import { defineComponent, ref, watch, PropType } from '@vue/composition-api'
import { IndividualPhone } from '../GeneratedTypes/IndividualPhone'
import PhoneInput from './PhoneInput.vue'
import { decrementingPhoneIDStrategy, getEmptyPhone } from '../lib/support/models/IndividualPhone/data'

export default defineComponent({
  name: 'PhonePanel',
  components: {
    PhoneInput,
  },
  props: {
    value: { type: Array as PropType<IndividualPhone[]>, required: true, default: () => [] },
    maxPhones: { type: Number, required: false, default: 99 },
  },
  setup(props) {
    const internalValue = ref<IndividualPhone[]>([])
    watch(
      () => props.value,
      () => {
        internalValue.value = props.value
        // if internal value doesn't have at least one phone then seed a phone
        if (!internalValue.value.length) {
          // our phone ids will be negative if not coming from the server, we'll convert them later.
          internalValue.value.push(getEmptyPhone(decrementingPhoneIDStrategy()))
        }
      },
      { immediate: true }
    )

    function addPhone() {
      // will add a new phone to the list.
      internalValue.value.push(getEmptyPhone(decrementingPhoneIDStrategy()))
    }

    return {
      internalValue,
      addPhone,
    }
  },
})
