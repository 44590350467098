import { DataTableHeader } from 'vuetify/types'

const coachLinkSearchHeaders = [
  { text: 'Name', value: 'name' },
  { text: 'Gender', value: 'gender' },
  { text: 'Existing Link', value: 'prettyConnectedTo' },
] as DataTableHeader[]

interface CoachLinkSelectionGridInfo {
  /** how the name should be formatted for display **/
  name: string
  /** unique ID for the volunteer **/
  individualID: number
  /** additional field per requirements */
  gender: string
  /** firstName easy deconstructed name **/
  firstName: string
  /** lastName for a deconstructed name **/
  lastName: string
  /** provides for a selected, puts a check on the selected */
  selected: boolean
  connectedCoachLinkFirstName: string
  connectedCoachLinkLastName: string
  connectedCoachLinkID: number
  prettyConnectedTo: string
}
const empty = (): CoachLinkSelectionGridInfo => {
  return {
    name: '',
    individualID: 0,
    gender: '',
    firstName: '',
    lastName: '',
    connectedCoachLinkFirstName: '',
    connectedCoachLinkLastName: '',
    connectedCoachLinkID: 0,
    prettyConnectedTo: '',
    selected: false,
  }
}
export { empty, coachLinkSearchHeaders, CoachLinkSelectionGridInfo }
