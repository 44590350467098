



































import { defineComponent, ref, watch, PropType } from '@vue/composition-api'

import { volunteerSearchHeaders } from '@/lib/components/CoachSelectionModal/VolunteerSelectionGridColumns'
import volunteersClient from '@/clients/volunteersClient'
import { PlayerSearchInfo } from '@/GeneratedTypes/ListInfo/PlayerSearchInfo'
import SearchModal from '@/elements/SearchModal.vue'
import { RolesEnum } from '@/lib/support/models/LeagueVolunteer/data'
import { VolunteerSearchInfo } from '@/GeneratedTypes/ListInfo/VolunteerSearchInfo'

type ANY_ROLE_TYPE = number[]
export const ANY_ROLE = [0]

interface VolunteerSearchInfoDisplay extends VolunteerSearchInfo {
  name: string
}

export default defineComponent({
  name: 'VolunteerSearch',
  components: {
    SearchModal,
  },
  props: {
    autoSearch: { required: false, type: String, default: '' },
    league: { required: true, type: String, default: '' },
    roles: { required: false, type: Array as PropType<RolesEnum[] | ANY_ROLE_TYPE>, default: () => ANY_ROLE },
    visible: { required: false, type: Boolean, default: false },
  },
  setup(props, ctx) {
    const showModal = ref(props.visible)
    /** Functionality Related to Search of Former Volunteer **/
    const searchData = ref<VolunteerSearchInfoDisplay[]>([]) //define when activated
    const searchActive = ref(false) //not showing initially

    watch(
      () => props.visible,
      () => {
        showModal.value = props.visible
      }
    )

    watch(
      () => showModal.value,
      (b: boolean) => {
        if (b) {
          ctx.emit('open')
        } else {
          ctx.emit('close')
        }
      }
    )

    watch(
      () => props.autoSearch,
      async function () {
        if (props.autoSearch) {
          const [last, first] = props.autoSearch.split(',')
          searchActive.value = true
          try {
            const result = [
              ...(await volunteersClient.search({
                leagueID: props.league,
                orSearch: false,
                role: props.roles,
                first,
                last,
                email: '',
                phone: '',
              })),
            ]

            searchData.value = result.map((v) => {
              return {
                ...v,
                name: `${v.firstName} ${v.lastName}`,
              }
            })
          } finally {
            searchActive.value = false
          }
          if (searchData.value.length) {
            showModal.value = true
          }
        }
      }
    )

    function searchSelected(p: PlayerSearchInfo) {
      ctx.emit('select', p.individualID)
    }

    /***
     * Callback on the SearchModal
     * @param s
     */
    async function updateSearch(s: string) {
      if (!searchActive.value) {
        searchActive.value = true
        try {
          const result = [
            ...(await volunteersClient.search({
              leagueID: props.league,
              role: props.roles,
              orSearch: true,
              first: s,
              last: s,
              email: '',
              phone: '',
            })),
          ]
          searchData.value = result.map((v) => {
            return {
              ...v,
              name: `${v.firstName} ${v.lastName}`,
            }
          })
        } finally {
          searchActive.value = false
        }
      }
    }

    return {
      volunteerSearchHeaders,
      showModal,
      searchData,
      searchActive,
      updateSearch,
      searchSelected,
    }
  },
})
