































import { defineComponent, ref, PropType, watch, computed, onMounted } from '@vue/composition-api'
import TextInput from '@/elements/TextInput.vue'
import { RolesEnum } from '@/lib/support/models/LeagueVolunteer/data'
import CoachLinkSelectionModal from '@/components/CoachSelectionModal.vue'
import {
  CoachLinkSelectionGridInfo,
  empty as emptyCoachLink,
} from '@/lib/components/CoachSelectionModal/CoachLinkSelectionGridColumns'
import { cloneDeep } from 'lodash'
import { CoachAssistantLink, empty as emptyCoachAssistantLink } from '@/GeneratedTypes/CoachAssistantLink'
import store from '@/store'

export default defineComponent({
  components: {
    TextInput,
    CoachLinkSelectionModal,
  },
  props: {
    league: { type: String, required: true, default: '' },
    typeProgramID: { type: String, required: true, default: '' },
    value: {
      type: Object as PropType<CoachAssistantLink>,
      required: true,
      default: emptyCoachAssistantLink(),
    },
    coachID: { type: Number, required: true, default: 0 },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const openInternal = ref(false)
    const coachesInternal = computed(() =>
      store.state.volunteers.volunteers.filter(
        (v) => (v.roles?.filter((r) => r.roleID == RolesEnum.COACH) ?? []).length > 0
      )
    )
    const internalValue = ref(cloneDeep(props.value) as CoachAssistantLink)
    const currentSelected = ref(emptyCoachLink())
    const searchTerm = ref('')
    const programID = ref(props.typeProgramID)

    const openModal = (show = true) => {
      openInternal.value = show
    }
    const coachSelected = (coach: CoachLinkSelectionGridInfo | null) => {
      currentSelected.value = coach ?? emptyCoachLink()
      volunteerFindToValueField(coach)
      emit('input', internalValue.value)
    }
    const volunteerFindToValueField = (coach: CoachLinkSelectionGridInfo | null) => {
      if (coach) {
        internalValue.value.coach2IndividualID = coach.individualID
        internalValue.value.coach1IndividualID = props.coachID
        internalValue.value.pendingLastName = ''
        internalValue.value.pendingFirstName = ''
        internalValue.value.pendingIndividualID = 0
      } else {
        internalValue.value = emptyCoachAssistantLink()
      }
    }
    const pendingCoachLink = (coach: CoachLinkSelectionGridInfo) => {
      internalValue.value.coach2IndividualID = 0
      internalValue.value.pendingIndividualID = coach.individualID
      internalValue.value.coach1IndividualID = props.coachID
      internalValue.value.pendingLastName = ''
      internalValue.value.pendingFirstName = ''
    }
    const selectedName = computed(() => {
      if (isCoachLinked.value) {
        const x = coachesInternal.value.find((c) => notMeLinkID.value == c.individualID)
        return x?.formattedNameFirstLast ?? ''
      }
      const x = coachesInternal.value.find((c) => internalValue.value.pendingIndividualID == c.individualID)
      if (x) {
        return x?.formattedNameFirstLast ?? ''
      }
      if (internalValue.value.pendingLastName) {
        return `${internalValue.value.pendingFirstName} ${internalValue.value.pendingLastName}`
      }
    })
    onMounted(async () => {
      await loadCoaches()
    })
    const loadCoaches = async () => {
      try {
        loading.value = true
        const shouldForce = true
        await store.dispatch.volunteers.loadProgramVolunteers({ leagueId: props.league, shouldForce })
      } finally {
        loading.value = false
      }
    }
    watch(
      () => props.value,
      () => {
        internalValue.value = cloneDeep(props.value)
      }
    )
    watch(
      () => props.typeProgramID,
      () => {
        programID.value = props.typeProgramID
      }
    )
    const notMeLinkID = computed(() => {
      if (internalValue.value.coach1IndividualID == props.coachID) {
        return internalValue.value.coach2IndividualID
      }
      return internalValue.value.coach1IndividualID
    })
    const shouldShowWarning = computed(() => {
      return (
        (!isCoachLinkPending.value && internalValue.value.pendingLastName) ||
        internalValue.value.pendingFirstName ||
        internalValue.value.pendingIndividualID
      )
    })
    const isCoachLinkPending = computed(() => {
      return (
        (!isCoachLinked.value && internalValue.value.pendingLastName) ||
        internalValue.value.pendingFirstName ||
        internalValue.value.pendingIndividualID
      )
    })
    const isCoachLinked = computed(() => {
      return !!notMeLinkID.value
    })
    const clear = () => {
      coachSelected(null)
    }
    return {
      openModal,
      selectedName,
      openInternal,
      searchTerm,
      coachSelected,
      shouldShowWarning,
      clear,
      programID,
      pendingCoachLink,
      coachesInternal,
      loading,
    }
  },
})
