import { RolesEnum } from '@/lib/support/models/LeagueVolunteer/data'
import { RoleHasCoachPreferences } from '@/views/Programs/Volunteers/ext/roleutils'

export interface InterfaceToggles {
  hasPracticeDays: boolean
  hasGradesToCoach: boolean
  hasShirtSize: boolean
  hasUDF: boolean
  hasApproval: boolean
  hasProgramSelect: boolean
  hasCoachHeader: boolean
}

export const interfaceNone: InterfaceToggles = {
  hasPracticeDays: false,
  hasGradesToCoach: false,
  hasShirtSize: false,
  hasUDF: false,
  hasApproval: false,
  hasProgramSelect: false,
  hasCoachHeader: false,
}
const interfaceAll: InterfaceToggles = {
  hasPracticeDays: true,
  hasGradesToCoach: true,
  hasShirtSize: true,
  hasUDF: true,
  hasApproval: true,
  hasProgramSelect: true,
  hasCoachHeader: true,
}
const interfaceCoach = interfaceAll

const interfaceRef = { ...interfaceNone, hasApproval: true }

export function interfaceOr(i: InterfaceToggles, i2: InterfaceToggles) {
  return {
    hasPracticeDays: i.hasPracticeDays || i2.hasPracticeDays,
    hasGradesToCoach: i.hasGradesToCoach || i2.hasGradesToCoach,
    hasShirtSize: i.hasShirtSize || i2.hasShirtSize,
    hasUDF: i.hasUDF || i2.hasUDF,
    hasApproval: i.hasApproval || i2.hasApproval,
    hasProgramSelect: i.hasProgramSelect || i2.hasProgramSelect,
    hasCoachHeader: i.hasCoachHeader || i2.hasCoachHeader,
  }
}

export function roleToInterface(role: RolesEnum | number) {
  if (RoleHasCoachPreferences(role)) {
    return interfaceCoach
  } else if (role == RolesEnum.REFEREE) {
    return interfaceRef
  } else {
    return interfaceNone
  }
}
