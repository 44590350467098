import { LeagueVolunteerRole } from '@/GeneratedTypes/LeagueVolunteerRole'
import { RolesEnum } from '@/lib/support/models/LeagueVolunteer/data'

export function getEmptyLeagueVolunteerRole(seed?: LeagueVolunteerRoleSeed): LeagueVolunteerRole {
  return {
    roleID: seed?.roleID || 0,
    approved: false,
    authorizedExpireDate: null,
    udFs: [],
    upwardAuthInvitationID: null,
  }
}

export class LeagueVolunteerRoleSeed {
  roleID = 0
}

export function roleIDSeed(Role: number | RolesEnum) {
  const c = new LeagueVolunteerRoleSeed()
  c.roleID = Role
  return c
}
