import { LeagueVolunteer } from '@/GeneratedTypes/LeagueVolunteer'

import { getEmptyIndividualAddress } from '@/lib/support/models/IndividualAddress/data'

import { RuntimeException } from '@/lib/common/exceptions/RuntimeException'

import { DecomposedArgs } from '@/views/Programs/Volunteers/ext/edittypes'
import { CoachProgram } from '@/GeneratedTypes/CoachProgram'
import { getEmptyCoachProgram, seedWithProgramID } from '@/lib/support/models/CoachProgram/data'
import { getFirstEmail } from '@/lib/support/models/IndividualEmail/email_list_operations'
import { cloneDeep } from 'lodash'
import { getEmptyLeagueCoach } from '@/lib/support/models/LeagueCoach/data'

class VolunteerDataRuntimeException extends RuntimeException {
  name = 'Volunteer Data Runtime Exception'
}

export function getProgram(input: LeagueVolunteer, programName: string): CoachProgram | null {
  if (input?.coachPreferences?.programs?.length) {
    const pi = input.coachPreferences.programs.find((x) => x.typeProgramID == programName)
    if (pi) {
      return pi
    }
    return getEmptyCoachProgram(seedWithProgramID(programName))
  }
  return null
}

/***
 * All assumptions about where form elements are coming from are made here.
 *
 * @param input incoming league player
 * @param programName
 * @return decomposed form members from a a leagueplayer
 */
export function decomposeLeagueVolunteer(input: LeagueVolunteer): DecomposedArgs {
  return cloneDeep({
    address: input?.addresses?.[0] || getEmptyIndividualAddress(),
    volunteer: input, //these are the basic top-level fields like name
    phones: input.phoneNumbers ?? [],
    roles: input.roles ?? [],
    email: getFirstEmail(input).emailAddress ?? '',
    coachPreferences: input.coachPreferences ?? getEmptyLeagueCoach(),
  })
}

/***
 * Combine the selected info thing and the template "loaded"
 * keeping the values under edit alive that don't need to be changed.
 * @param edit
 * @param template
 */

export function reconcileTemplate(edit: LeagueVolunteer, template: LeagueVolunteer): LeagueVolunteer {
  let rv = edit
  if (!edit || !edit.firstName || !edit.lastName) {
    rv = template
  }

  if (!edit || !template) {
    throw new VolunteerDataRuntimeException('expected edit and template to be set on reconcile')
  }
  rv.accountNumber = template.accountNumber
  rv.individualID = template.individualID
  rv.coachPreferences = template.coachPreferences
  rv.roles = template.roles
  rv.phoneNumbers = template.phoneNumbers
  rv.addresses = template.addresses
  rv.emailAddresses = template.emailAddresses
  rv.birthDate = template.birthDate

  return rv
}
